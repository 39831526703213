import { Routes } from "@utils/routes";
import { IconMap } from "components/shared/Icon";
import { TeacherJobBoardIndicatorBadges } from "../components/counters";
import { SidebarLink } from "../types";

export const getCommonTeacherLinks = (currentPathname?: string) =>
  [
    {
      name: "Dashboard",
      route: Routes.home,
      icon: IconMap["dashboard"],
      current: Routes.home.path() === currentPathname,
    },
    {
      name: "Engagements",
      route: Routes.engagements,
      icon: IconMap["engagement"],
      current: currentPathname?.startsWith(Routes.engagements.path()),
    },
    {
      name: "My Schedule",
      route: Routes.mySchedule,
      icon: IconMap["calendar"],
      current: Routes.mySchedule.path() === currentPathname,
    },
  ] as SidebarLink[];

export const getMentorTeacherLinks = (currentPathname?: string) =>
  getCommonTeacherLinks(currentPathname);

export const getTeacherTutorLinks = (
  hidePaymentDetails: boolean,
  currentPathname?: string
) =>
  [
    ...getCommonTeacherLinks(currentPathname),
    {
      name: "Job Board",
      route: Routes.job.board,
      icon: IconMap["job"],
      current: currentPathname?.startsWith("/job/"),
      counter: TeacherJobBoardIndicatorBadges,
    },
    {
      name: "My Attendance",
      route: Routes.myAttendance,
      icon: IconMap["attendance"],
      current: Routes.myAttendance.path() === currentPathname,
    },
    ...(hidePaymentDetails
      ? []
      : [
          {
            name: "My Payments",
            route: Routes.myPayments,
            icon: IconMap["payment"],
            current: Routes.myPayments.path() === currentPathname,
          },
        ]),
    {
      name: "My PD",
      route: Routes.myPD,
      icon: IconMap["teacher"],
      current: Routes.myPD.path() === currentPathname,
    },
    {
      name: "My Profile",
      route: Routes.myProfile,
      icon: IconMap["profile"],
      current: Routes.myProfile.path() === currentPathname,
    },
  ] as SidebarLink[];
