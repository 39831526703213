import { gql } from "@apollo/client";
import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  GradeLevel,
} from "@generated/graphql";
import { SelectMenuOption } from "@shared";
import { getCohortSubjectText, getGradeLevelText } from "@utils/labels";
import { sortGradeLevels } from "@utils/sort";
import { assertUnreachable } from "@utils/types";
import { EventScheduleStatus } from "types/events";
import { AbsentType, GradeType, SubjectOption } from "./types";

export const NO_MATCH = "No match";

export const NO_MATCH_OPTION: SelectMenuOption = {
  id: "NONE",
  value: NO_MATCH,
};

export const statusButtonOptions: CohortSessionStudentAttendanceStatus[] = [
  CohortSessionStudentAttendanceStatus.Present,
  CohortSessionStudentAttendanceStatus.Partial,
  CohortSessionStudentAttendanceStatus.Absent,
];

export const TT_DASH_SORT_ORDER = [
  EventScheduleStatus.ONGOING,
  EventScheduleStatus.UPCOMING,
  EventScheduleStatus.CONCLUDED,
];

export const GET_STUDENT_LIVE_PARTICIPANT_MATCHES = gql`
  query CohortSessionStudentAttendanceMatches($cohortSessionId: ID!) {
    cohortSessionStudentAttendanceMatches(cohortSessionId: $cohortSessionId) {
      match
      student {
        fullName
        id
      }
      distances {
        distance
        participant
      }
    }
  }
`;

export const cohortSubjectOptions: SubjectOption[] = Object.values(
  CohortAssignmentSubject
).map((subject) => ({
  id: subject,
  value: getCohortSubjectText(subject as CohortAssignmentSubject),
  subject: subject.valueOf() as CohortAssignmentSubject,
}));

export const mathSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Algebra_1,
  CohortAssignmentSubSubject.Algebra_2,
  CohortAssignmentSubSubject.Geometry,
  CohortAssignmentSubSubject.IntegratedMath_1,
  CohortAssignmentSubSubject.IntegratedMath_2,
  CohortAssignmentSubSubject.IntegratedMath_3,
];

export const scienceSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Biology,
  CohortAssignmentSubSubject.EarthSpace,
  CohortAssignmentSubSubject.Chemistry,
  CohortAssignmentSubSubject.Physics,
];

export const socialScienceSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.WorldHistory,
  CohortAssignmentSubSubject.AmericanHistory,
  CohortAssignmentSubSubject.Government,
];

export const elaSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.English_9,
  CohortAssignmentSubSubject.English_10,
  CohortAssignmentSubSubject.English_11,
  CohortAssignmentSubSubject.English_12,
  CohortAssignmentSubSubject.Fls,
  CohortAssignmentSubSubject.Ufli,
];

export const eldSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Ufli,
  CohortAssignmentSubSubject.Off2Class,
];

export const generalSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
];

export const psatSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Math,
  CohortAssignmentSubSubject.ReadingWriting,
];

export const satSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Math,
  CohortAssignmentSubSubject.ReadingWriting,
];

export const actSubSubjectOptions: CohortAssignmentSubSubject[] = [
  CohortAssignmentSubSubject.General,
  CohortAssignmentSubSubject.Math,
  CohortAssignmentSubSubject.English,
  CohortAssignmentSubSubject.Reading,
  CohortAssignmentSubSubject.Science,
];
export const GRADE_TYPE_TO_ABSENT_TYPE_MAP: Record<
  GradeType,
  AbsentType | null
> = {
  participationGrade: null,
  languageArtsGrade: AbsentType.LanguageArtsAbsence,
  readingGrade: AbsentType.ReadingAbsence,
  classworkGrade: AbsentType.ClassworkAbsence,
};

export const absentKeys: AbsentType[] = [
  AbsentType.ReadingAbsence,
  AbsentType.LanguageArtsAbsence,
  AbsentType.ClassworkAbsence,
];

export const gradeKeys: GradeType[] = [
  GradeType.Reading,
  GradeType.LanguageArts,
  GradeType.Classwork,
  GradeType.Participation,
];

export const elaSubjectGradeOptions: GradeType[] = gradeKeys.filter(
  (gradeKey) => {
    switch (gradeKey) {
      case GradeType.Reading:
      case GradeType.LanguageArts:
      case GradeType.Participation:
        return true;
      case GradeType.Classwork:
        return false;
      default:
        assertUnreachable(gradeKey, "gradeKey");
    }
  }
);

export const nonElaSubjectGradeOptions: GradeType[] = gradeKeys.filter(
  (gradeKey) => {
    switch (gradeKey) {
      case GradeType.Participation:
      case GradeType.Classwork:
        return true;
      case GradeType.Reading:
      case GradeType.LanguageArts:
        return false;
      default:
        assertUnreachable(gradeKey, "gradeKey");
    }
  }
);

export const gradeLevelFiltersPanelOptions: SelectMenuOption[] = [
  {
    id: "",
    value: "All Student Grades",
  },
  ...Object.values(GradeLevel)
    .map((gradeLevel) => ({
      id: gradeLevel as string,
      value: getGradeLevelText(gradeLevel),
    }))
    .filter(({ id }) => (id as GradeLevel) !== GradeLevel.Unknown)
    .sort((a, b) => sortGradeLevels(a.id as GradeLevel, b.id as GradeLevel)),
] as const;

export const gradeTitles: Record<GradeType, string> = {
  [GradeType.Reading]: "Reading",
  [GradeType.LanguageArts]: "Language Arts",
  [GradeType.Classwork]: "Classwork",
  [GradeType.Participation]: "Participation",
};

export const NOT_IN_ENGAGEMENT_STYLE = "!bg-gray-200/70";
export const FUTURE_STYLE = "bg-sky-900/[0.03]";
export const ENGAGEMENT_EXCLUSION_STYLE = "!bg-blue-900/[0.10]";
export const OUTSIDE_DATE_FILTER_STYLE = "bg-fuchsia-900/10";
export const OUTSIDE_STUDENT_RANGE_STYLE = "!bg-gray-200/60";

export const COHORTS_COL_MIN_WIDTH = 90;
