import {
  CalendarInfoHoliday,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortSessionStudentAttendanceStatus,
  GradeLevel,
  StudentGradesTable_StudentGradesRecordFragment,
} from "@generated/graphql";
import {
  CohortDaysMap,
  CohortExclusionDayMap,
  ExclusionISODays,
  GradeType,
  IsCohortExclusionDays,
  IsEngagementExclusionDays,
  NoTutoringDaysMap,
  WeekdayData,
} from "@shared/AttendanceGrades/types";
import { SelectMenuOption } from "@shared/Inputs";
import {
  ISODate,
  LocalizedWeekday,
  getLocalizedWeekdays,
  normalizeDateFromUTCDateTime,
} from "@utils/dateTime";
import {
  CohortAssignmentSubSubjectTextMode,
  getCohortSubSubjectText,
  getGradeLevelText,
} from "@utils/labels";
import { sortGradeLevels } from "@utils/sort";
import { makeCacheKey } from "@utils/strings";
import { assertUnreachable } from "@utils/types";
import { endOfWeek, formatISO, startOfDay, startOfWeek } from "date-fns";
import { uniq } from "lodash";
import { AttendanceStatus } from "types/global";
import {
  COHORTS_COL_MIN_WIDTH,
  actSubSubjectOptions,
  elaSubSubjectOptions,
  elaSubjectGradeOptions,
  eldSubSubjectOptions,
  generalSubSubjectOptions,
  mathSubSubjectOptions,
  nonElaSubjectGradeOptions,
  psatSubSubjectOptions,
  satSubSubjectOptions,
  scienceSubSubjectOptions,
  socialScienceSubSubjectOptions,
} from "./constants";
import {
  ANY_SUB_SUBJECT,
  StudentAttendanceStyleData,
  SubSubjectAllOption,
  SubSubjectOption,
  SubjectComboKey,
  SubjectComboWithAll,
} from "./types";

export const isNotAttended = (
  status?: CohortSessionStudentAttendanceStatus
): boolean =>
  !status ||
  status === CohortSessionStudentAttendanceStatus.Unknown ||
  status === CohortSessionStudentAttendanceStatus.Absent;

export const negOneIsNull = (num?: number | null) => (num === -1 ? null : num);

export const makeAttendanceKey = (
  cohortSessionId: number | string,
  studentId: number | string
) => makeCacheKey(cohortSessionId, studentId);

export const getSubjectSubSubjectOptions = (
  subject: CohortAssignmentSubject
) => {
  switch (subject) {
    case CohortAssignmentSubject.Math:
      return mathSubSubjectOptions;
    case CohortAssignmentSubject.Ela:
      return elaSubSubjectOptions;
    case CohortAssignmentSubject.Science:
      return scienceSubSubjectOptions;
    case CohortAssignmentSubject.SocialStudies:
      return socialScienceSubSubjectOptions;
    case CohortAssignmentSubject.Eld:
      return eldSubSubjectOptions;
    case CohortAssignmentSubject.Psat:
      return psatSubSubjectOptions;
    case CohortAssignmentSubject.Sat:
      return satSubSubjectOptions;
    case CohortAssignmentSubject.Act:
      return actSubSubjectOptions;
    case CohortAssignmentSubject.General:
      return generalSubSubjectOptions;
    default:
      assertUnreachable(subject, "subject");
  }
};

function createSubSubjectOptions(
  subSubjects: CohortAssignmentSubSubject[],
  selectedLabelMode?: CohortAssignmentSubSubjectTextMode
): SubSubjectOption[] {
  return subSubjects.map((subSubject) => ({
    id: subSubject,
    value: getCohortSubSubjectText(subSubject),
    subSubject,
    ...(selectedLabelMode && {
      selectedLabel: getCohortSubSubjectText(subSubject, selectedLabelMode),
    }),
  }));
}

export function getSubSubjectOptions(
  subject?: CohortAssignmentSubject,
  selectedLabelMode?: CohortAssignmentSubSubjectTextMode
): SubSubjectOption[] {
  if (!subject) return [];
  const subSubjects = getSubjectSubSubjectOptions(subject);
  return createSubSubjectOptions(subSubjects, selectedLabelMode);
}

export function getSubSubjectAllOptions(
  subject?: CohortAssignmentSubject,
  selectedLabelMode?: CohortAssignmentSubSubjectTextMode
): SubSubjectAllOption[] {
  if (!subject) return [];
  const subSubjects = getSubjectSubSubjectOptions(subject);
  return [
    {
      id: ANY_SUB_SUBJECT,
      value: ANY_SUB_SUBJECT,
      subSubject: ANY_SUB_SUBJECT,
    },
    ...createSubSubjectOptions(subSubjects, selectedLabelMode),
  ];
}

export const getSubjectComboKey = (
  subjectCombo: SubjectComboWithAll
): SubjectComboKey =>
  `${subjectCombo.subject}-${subjectCombo.subSubject ?? ANY_SUB_SUBJECT}`;

export const getSubjectComboFromKey = (combo: string) => {
  const [subject, subSubject] = combo.split("-") as [
    CohortAssignmentSubject,
    CohortAssignmentSubSubject | typeof ANY_SUB_SUBJECT,
  ];
  return {
    subject,
    subSubject: subSubject === ANY_SUB_SUBJECT ? null : subSubject,
  };
};

export const getSubjectAllCombo = (
  subject: CohortAssignmentSubject
): SubjectComboWithAll => ({ subject, subSubject: null });

export const getAttendanceStatusStyles = (
  status: AttendanceStatus
): StudentAttendanceStyleData => {
  switch (status) {
    case CohortSessionStudentAttendanceStatus.Present:
      return {
        peepColor: "text-emerald-500",
        iconType: "check",
        pillColor: "bg-emerald-500 border-emerald-600 bg-opacity-90",
      };
    case CohortSessionStudentAttendanceStatus.Partial:
      return {
        peepColor: "text-amber-500",
        iconType: "alarm",
        pillColor: "bg-amber-500 border-amber-600 bg-opacity-90",
      };
    case CohortSessionStudentAttendanceStatus.Absent:
      return {
        peepColor: "text-rose-500",
        iconType: "cross",
        pillColor: "bg-rose-500 border-rose-600 bg-opacity-80",
      };

    case CohortSessionStudentAttendanceStatus.Unknown:
      return {
        peepColor: "text-gray-800",
        iconType: "question",
        pillColor: "bg-gray-950 border-slate-950 bg-opacity-60",
      };
    default:
      assertUnreachable(status as never, "Attendance Status Issue");
  }
};

export const getGradeOptions = (subject: CohortAssignmentSubject) =>
  subject === CohortAssignmentSubject.Ela
    ? elaSubjectGradeOptions
    : nonElaSubjectGradeOptions;

const getNoTutoringISOMap = (
  noTutoringDays: CalendarInfoHoliday[]
): NoTutoringDaysMap => {
  const isoDaysMap: NoTutoringDaysMap = {};

  noTutoringDays.forEach(({ name, type, startDate, endDate }) => {
    const start = normalizeDateFromUTCDateTime(new Date(startDate));
    const end = normalizeDateFromUTCDateTime(new Date(endDate));
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      const isoDate = date.toISOString().split("T")[0];
      if (!isoDaysMap[isoDate]) isoDaysMap[isoDate] = [];
      isoDaysMap[isoDate].push({ name, type });
    }
  });

  return isoDaysMap;
};

export const getExclusionISODays = (
  cohorts: {
    id: string;
    studentNoShowDays: Date[];
    studentAssessmentDays: Date[];
  }[],
  engagement: {
    noTutoringDays: CalendarInfoHoliday[];
    studentNoShowDays: Date[];
    studentAssessmentDays: Date[];
  }
): ExclusionISODays => {
  const engagementNoTutoringISODays: NoTutoringDaysMap = getNoTutoringISOMap(
    engagement.noTutoringDays
  );

  const engagementNoShowISODays = engagement.studentNoShowDays.map(
    (d) => normalizeDateFromUTCDateTime(new Date(d)).toISOString().split("T")[0]
  );

  const engagementAssessmentISODays = engagement.studentAssessmentDays.map(
    (d) => normalizeDateFromUTCDateTime(new Date(d)).toISOString().split("T")[0]
  );

  const cohortsExclusionISODays: CohortDaysMap = cohorts.reduce(
    (acc, { id, studentNoShowDays, studentAssessmentDays }) => {
      acc[id] = {
        cohortNoShowISODays: studentNoShowDays.map(
          (d) =>
            normalizeDateFromUTCDateTime(new Date(d))
              .toISOString()
              .split("T")[0]
        ),
        cohortAssessmentISODays: studentAssessmentDays.map(
          (d) =>
            normalizeDateFromUTCDateTime(new Date(d))
              .toISOString()
              .split("T")[0]
        ),
      };
      return acc;
    },
    {} as CohortDaysMap
  );

  return {
    engagementNoShowISODays,
    cohortsExclusionISODays,
    engagementNoTutoringISODays,
    engagementAssessmentISODays,
  };
};

export const getLocalISODay = (isoDate: string): Date => {
  const [year, month, day] = isoDate.split("-").map(Number);
  return startOfDay(new Date(year, month - 1, day));
};

export const isEngExclusionDay = (exclusionData: IsEngagementExclusionDays) =>
  exclusionData.isEngagementNoShowDay ||
  exclusionData.isEngagementAssessmentDay ||
  exclusionData.isEngagementNoTutoringDay.length > 0;

export const isCohortDay = ({
  isCohortNoShowDay,
  isCohortAssessmentDay,
}: IsCohortExclusionDays) => isCohortNoShowDay || isCohortAssessmentDay;

export const buildWeekdayData = (
  startDate: Date,
  endDate: Date,
  weekViewDate: Date,
  exclusionISODays: ExclusionISODays,
  cohortIds: string[]
): WeekdayData[] => {
  const startIso: ISODate = formatISO(normalizeDateFromUTCDateTime(startDate), {
    representation: "date",
  });
  const endIso: ISODate = formatISO(normalizeDateFromUTCDateTime(endDate), {
    representation: "date",
  });

  return getLocalizedWeekdays(
    formatISO(weekViewDate, { representation: "date" })
  ).map((localizedWeekday) => {
    const isoDate = localizedWeekday.isoDate;
    const exclusionData = getIsEngagementExclusionDay(
      exclusionISODays,
      localizedWeekday.isoDate
    );

    const cohortExclusionDayMap: CohortExclusionDayMap = {};

    cohortIds.forEach(
      (id) =>
        (cohortExclusionDayMap[id] = getIsCohortExclusionDay(
          exclusionISODays,
          isoDate,
          id
        ))
    );

    return {
      ...localizedWeekday,
      cohortExclusionDayMap,
      engExclusionData: exclusionData,
      isEngExclusionDay: isEngExclusionDay(exclusionData),
      notInEngagement: startIso > isoDate || endIso < isoDate,
      isInFuture: Date.now() < getLocalISODay(isoDate).getTime(),
    };
  });
};

export const getGradeFilterOptions = (
  students: { studentGrade: GradeLevel }[]
): SelectMenuOption[] => {
  const studentsGrades = uniq(students.map((s) => s.studentGrade));

  return [
    {
      id: "",
      value: "All Student Grades",
    },
    ...Object.values(GradeLevel)
      .map((gradeLevel) => ({
        id: gradeLevel as string,
        value: getGradeLevelText(gradeLevel),
      }))
      .filter(
        ({ id }) =>
          (id as GradeLevel) !== GradeLevel.Unknown &&
          studentsGrades.includes(id as GradeLevel)
      )
      .sort((a, b) => sortGradeLevels(a.id as GradeLevel, b.id as GradeLevel)),
  ];
};

export const getLongestCohortNameWidth = (
  cohortNamesListArray: string[]
): number => {
  const longestString = cohortNamesListArray.reduce(
    (a, b) => (a.length > b.length ? a : b),
    ""
  );
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return COHORTS_COL_MIN_WIDTH;
  context.font = "22px Arial";

  return Math.max(
    Math.round(context.measureText(longestString).width),
    COHORTS_COL_MIN_WIDTH
  );
};

export const isInLocalizedDayRange = (
  sessionDay: Date,
  startBound?: Date | null,
  endBound?: Date | null
): boolean => {
  const sessionTime = new Date(sessionDay).setHours(0, 0, 0, 0);
  const startTime = startBound
    ? new Date(startBound).setHours(0, 0, 0, 0)
    : null;
  const endTime = endBound ? new Date(endBound).setHours(0, 0, 0, 0) : null;

  return (
    (!startTime || sessionTime >= startTime) &&
    (!endTime || sessionTime <= endTime)
  );
};

export const getIsEngagementExclusionDay = (
  exclusionISODays: ExclusionISODays,
  isoDate: string
): IsEngagementExclusionDays => ({
  isEngagementNoTutoringDay:
    exclusionISODays.engagementNoTutoringISODays[isoDate] || [],
  isEngagementNoShowDay:
    exclusionISODays.engagementNoShowISODays.includes(isoDate),
  isEngagementAssessmentDay:
    exclusionISODays.engagementAssessmentISODays.includes(isoDate),
});

export const getIsCohortExclusionDay = (
  exclusionISODays: ExclusionISODays,
  isoDate: string,
  cohortId: string
): IsCohortExclusionDays => ({
  isCohortNoShowDay:
    exclusionISODays.cohortsExclusionISODays[
      cohortId
    ]?.cohortNoShowISODays.includes(isoDate) || false,
  isCohortAssessmentDay:
    exclusionISODays.cohortsExclusionISODays[
      cohortId
    ]?.cohortAssessmentISODays.includes(isoDate) || false,
});

export const inEngagement = (
  weekViewDate: Date,
  engagementStart: Date,
  engagementEnd: Date
) => {
  return (
    startOfWeek(weekViewDate).getTime() < engagementEnd.getTime() &&
    endOfWeek(weekViewDate).getTime() > engagementStart.getTime() &&
    startOfWeek(weekViewDate).getTime() < Date.now()
  );
};

export const getWeekData = (
  hasWeekendSessions: boolean,
  weekdayData: WeekdayData[]
) =>
  hasWeekendSessions
    ? weekdayData
    : weekdayData.filter(({ weekday }) => weekday !== 0 && weekday !== 6);

export const isAbsentFromAssessment = (
  gradesRecord: StudentGradesTable_StudentGradesRecordFragment,
  gradeType: GradeType
) => {
  switch (gradeType) {
    case GradeType.Reading:
      return gradesRecord.readingAbsentFromAssessment;
    case GradeType.Classwork:
      return gradesRecord.classworkAbsentFromAssessment;
    case GradeType.LanguageArts:
      return gradesRecord.languageArtsAbsentFromAssessment;
    default:
      return false;
  }
};

export const customToFixed = (num?: number | null): string =>
  num === undefined || num === null
    ? "--"
    : num === 0
    ? "0%"
    : `${num.toFixed(1)}%`;

export const isBeforeStudentStart = (
  isoDayDate: string,
  studentStart: Date
): boolean =>
  startOfDay(new Date(studentStart)).getTime() >
  getLocalISODay(isoDayDate).getTime();

export const isAfterStudentRemoved = (
  isoDayDate: string,
  studentRemoved: Date | null
): boolean => {
  if (!studentRemoved) return false;
  return (
    startOfDay(new Date(studentRemoved)).getTime() <
    getLocalISODay(isoDayDate).getTime()
  );
};

export const getISODayStudentId = (
  studentId: string,
  isoDay: string,
  subjectString: string
): string => `${studentId}-${isoDay}-${subjectString}`;

export const isSameLocalizedDay = (
  sessionDay: Date,
  weekday: LocalizedWeekday
): boolean =>
  weekday.year === new Date(sessionDay).getFullYear() &&
  weekday.month - 1 === new Date(sessionDay).getMonth() &&
  weekday.day === new Date(sessionDay).getDate();
